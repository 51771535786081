* {
  box-sizing: border-box;
}
:root {
  --header-height: 80px;
}

body {
  background-color: #fff;
  margin: 0;
  box-sizing: inherit;
  padding: 0;
}

.header {
  display: flex;
  height: 80px;
  box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.05);
  align-items: center;
}

.header > div > button {
  margin-left: auto;

  background: var(
    --Primary-Gold-Gradient,
    linear-gradient(
      89deg,
      #fecd6a 0.37%,
      #a56127 23.9%,
      #bc802b 34.68%,
      #d0a446 46.44%,
      #f7ea9c 59.18%,
      #c68e2e 72.9%,
      #9f702a 98.38%
    )
  );
  outline: none;
  width: 120px;
  height: 43px;
  color: #000;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  border-radius: 100px;

  font-weight: 500;
  line-height: normal;
  border: none;
  cursor: pointer;
}

header > div {
  display: flex;
  align-items: center;
}

.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 576px) {
  .container,
  .container-sm {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container,
  .container-md,
  .container-sm {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1140px;
  }
}
@media (min-width: 1400px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1320px;
  }
}

.search {
  height: calc(100vh - var(--header-height));
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.page-content {
  margin-top: calc(var(--header-height) * -1);
}

.input-wrapper {
  margin-top: 40px;
  width: 500px;
  height: 54px;

  position: relative;
}

@media (max-width: 767px) {
  .input-wrapper {
    width: 100%;
  }
}

.search input {
  width: 100%;
  display: block;
  border: 1px solid transparent;
  border-radius: 100px;
  padding: 18px 10px 18px 24px;
  color: #343443;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  outline: none;
  background: #faf6ec;
}

.link:visited,
.link:active,
.link:link {
  text-decoration: none;
}

.search input::placeholder {
  color: #c6c6cc;
}

.search input:focus::placeholder {
  color: #b1a07b;
}

.search input:focus {
  background: #f6edda;
  border: 1px solid transparent;
}

.search-submit {
  border: none;
  outline: none;
  border-radius: 50%;
  background: #343443;
  width: 40px;
  height: 40px;
  position: absolute;
  right: 13px;
  top: 57%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.search-icon {
  border: none;
  outline: none;
  border-radius: 12px;
  background: none;
  position: absolute;
  left: 13px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
}
